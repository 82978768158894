import React, { useContext } from "react";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";

const BrandsList = ({ list }) => {
  return (
    <ul className="brands-list xs:mt-v16 xs:mb-v16">
      {list.map((item, index) => (
        <Brand {...item} key={item.id} delay={index + 1} />
      ))}
    </ul>
  );
};

export default BrandsList;

const Brand = ({ url, logo, delay, name }) => {
  const { device } = useContext(DocumentContext);
  return (
    <li
      className={`animation-appear animation-delay-${4 +
        delay} grid-end-2 relative bg-black brand transition-filter`}
    >
      <a
        href={url}
        className="relative flex items-center justify-center w-full h-full pt-v2 pr-v2 pb-v2 pl-v2 xs:pt-v2 xs:pb-v3"
        rel="noopener noreferrer"
        target="_blank"
      >
        {device === `mobile` ? (
          <Img
            className="b1 text-hard-red"
            fluid={logo.childImageSharp.fluid}
            style={{
              width: `100%`,
              maxWidth: `${name !== "atelier" ? "21.6vw" : "30vw"}`,
              height: `min-content`,
            }}
            alt="logo"
          />
        ) : (
          <Img
            className="b1 text-hard-red"
            fluid={logo.childImageSharp.fluid}
            style={{
              width: `100%`,
              maxWidth: `${name !== "atelier" && "6.32vw"}`,
              height: `min-content`,
            }}
            alt="logo"
          />
        )}
      </a>
    </li>
  );
};
