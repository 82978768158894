/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import Div100vh from "react-div-100vh";
import AppearOnScroll from "~components/AppearOnScroll";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import ContactHeader from "~components/ContactHeader";
import Footer from "~components/Footer";
import BrandsList from "~components/BrandsList";
import Logo from "~components/svg/Logo";

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { brands } = frontmatter;

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="index-page w-full relative bg-ps pb-v12">
        <Div100vh className="w-screen relative pt-v2 xs:pt-v12 pb-v2 xs:pb-v12 flex flex-col justify-between text-hard-red">
          <ContactHeader color="hard-red" />

          <div className="grid">
            <h1 className="animation-appear-slow animation-delay-5 grid-end-6 xs:grid-end-10 f1 relative w-max main-title">
              <Logo color="hard-red" />
            </h1>
          </div>
        </Div100vh>

        <AppearOnScroll delay="5">
          <section className="w-full relative mt-v10 xs:mt-v9 mb-v4 xs:mb-v30 text-hard-red">
            <article className="grid xs:pt-v10">
              <h5 className="grid-end-12 mb-v1 xs:mb-v6 b2">
                The Value of Patience
              </h5>

              <p className="grid-end-12 f2">
                We provide patient capital and working knowledge to
                Australian-made businesses looking to create long-term, tangible
                value.
              </p>
            </article>
          </section>
        </AppearOnScroll>

        <section className="w-full relative mt-v16 text-hard-red">
          <AppearOnScroll delay="5">
            <div className="grid sm:flex sm:flex-col">
              <div className="grid-end-6 xs:w-4/5">
                <h2 className="mb-v2 xs:mb-v3 f2">
                  We invest in early stage Consumer Brands.
                </h2>
                <p className="b2 mb-v4 xs:mb-v12">
                  Do you belong on this list?
                </p>
                <a
                  href="mailto:enquiries@ordinaryequity.com.au"
                  className="get-in-touch transition-background b2"
                >
                  + Get in Touch
                </a>
              </div>
              <AppearOnScroll className="grid-end-6 grid-start-7">
                {(visible) => {
                  if (visible) {
                    return <BrandsList list={brands} />;
                  }
                  return null;
                }}
              </AppearOnScroll>
            </div>
          </AppearOnScroll>
        </section>
      </Layout>
      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        brands {
          id
          logo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          url
          name
        }
        footerImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        seoDescription
        seoKeywords
      }
    }
  }
`;
